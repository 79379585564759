{
  "name": "ig4u-ui",
  "version": "0.0.1-SNAPSHOT.150",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build --localize",
    "watch": "ng build --watch --configuration local",
    "test": "ng test",
    "extract-i18n": "ng extract-i18n",
    "ssr:copy:proxy": "cp proxy.mjs dist/ig4u-ui/server",
    "ssr:copy:robots": "cp robots.txt dist/ig4u-ui/server",
    "ssr:copy:locales": "cp locales.json dist/ig4u-ui/server",
    "ssr:copy:indexing": "cp indexing.json dist/ig4u-ui/server",
    "serve:ssr:ig4u-ui": "node dist/ig4u-ui/server/proxy.mjs",
    "stats": "ng build --configuration prod --stats-json --named-chunks",
    "lint": "ng lint",
    "lint:fix": "ng lint --fix",
    "prettier": "npx prettier --write ."
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.0",
    "@angular/common": "^17.0.0",
    "@angular/compiler": "^17.0.0",
    "@angular/core": "^17.0.0",
    "@angular/forms": "^17.0.0",
    "@angular/platform-browser": "^17.0.0",
    "@angular/platform-browser-dynamic": "^17.0.0",
    "@angular/platform-server": "^17.0.0",
    "@angular/router": "^17.0.0",
    "@angular/ssr": "^17.3.6",
    "@sentry/angular": "^8.2.1",
    "@sentry/cli": "^2.31.2",
    "express": "^4.18.2",
    "lightgallery": "^2.7.2",
    "ng-recaptcha": "^13.2.1",
    "ng-zorro-antd": "^17.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "xmlbuilder": "^15.1.1",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.6",
    "@angular-eslint/builder": "17.5.3",
    "@angular-eslint/eslint-plugin": "17.5.3",
    "@angular-eslint/eslint-plugin-template": "17.5.3",
    "@angular-eslint/schematics": "17.5.3",
    "@angular-eslint/template-parser": "17.5.3",
    "@angular/cli": "^17.3.6",
    "@angular/compiler-cli": "^17.0.0",
    "@angular/localize": "^17.3.6",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^18.18.0",
    "@typescript-eslint/eslint-plugin": "7.11.0",
    "@typescript-eslint/parser": "7.11.0",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.2.1",
    "googleapis": "^144.0.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "openai": "^4.47.2",
    "prettier": "^3.3.3",
    "prettier-eslint": "^16.3.0",
    "typescript": "~5.2.2"
  }
}
