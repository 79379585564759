{
  "host": "https://ig4u.co",
  "endpoints": [
    "",
    "story-viewer",
    "avatar-downloader",
    "photo-downloader",
    "reel-downloader",
    "video-downloader",
    "about-project",
    "cookie-policy",
    "privacy-policy",
    "terms-and-conditions"
  ]
}
